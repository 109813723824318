body {
    font-family: 'Inter', sans-serif;
}

.textColor {
   color: #475467 !important;
}

.main-heading {
    color: var(--gray-900, #101828);
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    line-height: 72px; /* 120% */
}

.subHeading {
    color: var(--gray-600, #475467);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 150% */
    padding-top: 32px;
}

.navbar {
    --bs-navbar-brand-padding-y: 1rem;
    --bs-navbar-nav-link-padding-x: 1.5rem;
}

.avatar-group {
    margin-top: 2rem;
}

.avatars {
    padding-left: 0;
    list-style: none;
    margin: 0;
}

.avatars > li + li {    
    margin-left: -20px;
}

.avatars > li {
    display: inline-block;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    color: #fff;
}

.form-text {
    color: #475467;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.stars-group {
    display: flex;
    align-items: center;
    gap: 4px;
}

.nav-icon {
    margin-right: 8px;
}

.nav-item .btn { 
    margin-left: 16px;
 }

 .nav-item .btn-link {
    --bs-btn-font-weight: 400;
    --bs-btn-color: ##344054;
    --bs-btn-bg: transparent;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: #3538CD;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-active-color: #3538CD;
    --bs-btn-active-border-color: transparent;
    --bs-btn-disabled-color: #6c757d;
    --bs-btn-disabled-border-color: transparent;
    --bs-btn-box-shadow: 0 0 0 #000;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    text-decoration: none;
}

.border-b {
    border-bottom: 1px solid #EAECF0;
}

.navbar-actions {
    --bs-nav-link-padding-x: 0;
    --bs-nav-link-padding-y: 0.5rem;
    --bs-nav-link-font-weight: ;
    --bs-nav-link-color: var(--bs-navbar-color);
    --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
    --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    flex-direction: row;
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    background: #0C111D;
}

.footer p {
    color: #EAECF0;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding-top: 24px;
}

.footer h4 {
    color: #D0D5DD;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
}

.footer ul a {
    color: #EAECF0;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
    text-decoration: none;
}

.footer ul li {
    list-style: none;
}

.footer ul {
    padding-left: 0px;
}

.copyright {
    padding: 48px 0px;
}

.copyright-text {
    color: #D0D5DD;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

.page-title-master {
    font-size: 20px !important;
    font-weight: 600;
    line-height: 24px; 
    font-style: normal;
    padding-top: 16px;
    padding-bottom: 16px;
}

.hr {
    border-top: 1px solid #EAECF0;
}

.dp-links a {
    display: table;
    margin-bottom: 8px;
}

.md-links button {
    display: table;
    margin-bottom: 8px;
    width: 150px;
}

.md-links-prs button {
    display: table;
    margin-bottom: 8px;
    width: 200px;
}

.days-links button {
    margin-right: 5px;
}

.input-group-text {
        border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.page-subtitle-master {
    font-size: 16px !important;
    font-weight: 600;
    line-height: 24px; 
    font-style: normal;
    padding-top: 16px;
}


.primary-bg {
    background-color: var(--bs-primary);
}

.stars-layout { 
    width: fit-content;
    margin: auto;
 }

 .testimonial {
    height: 580px;
    vertical-align: middle;
    color: #ffffff;
 }

 .testimonial p {
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px
 }

 .testimonial h2 {
    color: #ffffff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    padding-top: 16px;
 }

 .testimonial h3 {
    color: #ffffff;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 150% */
 }

.m-20-percent {
    margin-top: 20%;
}

.testimonial .avatar {
    width: 60px;
    height: 60px;
}

.CT-tabs .nav-link.active {
    background-color: #ffffff !important;
    border-left: 4px solid #3538CD;
    color: #475467;
}

.CT-tabs .nav-link.active h2 {
    color: #3538CD !important;
}

.CT-tabs .nav-link {
    border-radius: 0px;
    border-left: 4px solid #F2F4F7;
    color: #475467;
    padding-top: 16px;
    padding-bottom: 16px;
}

.CT-tabs h2 {
    color: #101828;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    padding-top: 0px;
}

.get-started {
    padding-top: 24px;
}

.section-title {
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    padding-bottom: 16px;
}

.section-support-text {
    font-size: 20px;
    color: #475467;
    font-style: normal;
    font-weight: 400;
    padding-bottom: 24px;
}

.login-header {
    color: #101828;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
}

.forgot-password {
    color: #3538CD;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; 
}

.primary-links {
    color: #3538CD;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; 
    text-decoration: none;
}

.m-10-percent {
    margin-top: 10%;
}

.modal h1 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.modal .form-control {
    font-size: 3rem;
}

.resend {
    color: #3538CD;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.has-error {
    color: red;
    font-size: 11px;
}

.navbar-collapse li {
    margin-right: 15px;
}

.navbar-collapse ul {
    margin-top: 4px;
}

.header-right-container {
    float: left;
    margin-left: 10px;
}

.error-block {
    height: 500px;
}

.loader-container {
    margin-left: 300px;
    margin-top: 150px;
}

.chart-container {
    margin-top: 7px;
}

.button-cus {
    background-color: #3538CD !important;
}

.mdb-button-wdith {
    width: 150px;
}
  